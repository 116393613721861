<template>
	<div id="tu-course-pay">
		<div class="tu-tutor">
			<div style="padding: 9px 0px;">{{ $t('course.check-course') }}</div>
		</div>
		<div class="tu-table">
			<el-table 
				v-if="course_list.length > 0 || loading"
				v-loading="loading"
				element-loading-text="Loading..."
				:data="course_list" 
				border 
				ref="payCourseRefTable"
				@selection-change="handleSelectionCourseChange"
				size="small">
				<el-table-column type="selection" width="55" />
				<el-table-column prop="me" :label="$t('Learner & Order Sn')">
					<template #default="scope">
						<div class="tu-nickname">{{ scope.row.me_info.nickname }}</div>
						<div class="tu-subject">{{ scope.row.order_sn }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" :label="$t('Name & Subject')">
					<template #default="scope">
						<div class="tu-nickname">{{ scope.row.user_info.nickname }}</div>
						<div class="tu-subject">{{ scope.row.subject.subject_name }}({{ scope.row.subject.level_name }})</div>
					</template>
				</el-table-column>
				<el-table-column prop="date" :label="$t('Date & Time')">
					<template #default="scope">
						<div class="tu-date">{{ handleDateShow(scope.row) }}</div>
						<div class="tu-time">{{ handleTimeShow(scope.row) }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="other" :label="$t('Price & Status')">
					<template #default="scope">
						<div class="tu-price">{{ scope.row.currency }}{{ scope.row.price }}</div>
						<div class="tu-status">{{ scope.row.status_name }}</div>
					</template>
			    </el-table-column>
		  	</el-table>	
		  	<div v-else>
				<el-empty :description="$t('No Data')"></el-empty>
			</div>
		</div>
		<el-divider />
		<div class="coupon">
			<div>{{ $t('coupon.use') }}</div>
			<div class="warp">
				<el-tabs v-model="tab_index">
					<el-tab-pane v-for="tab in coupon.tabs" :key="tab.value" :label="$t(tab.name)" :name="tab.value">
						<el-table 
							:ref="tab.value==1?'couponTableRef':'couponTableRefN'"
							v-if="coupon.list.length > 0 || loading"
							v-loading="loading"
							element-loading-text="Loading..."
							:data="coupon.list" 
							:highlight-current-row="tab_index==1?true:false"
							@current-change="onSelectCouponEvent"
							size="small">
							<el-table-column prop="me" :label="$t('coupon.fee')">
								<template #default="scope">
									<div class="tu-nickname">{{ scope.row.fee }}元</div>
									<div class="tu-subject">{{ scope.row.claim }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="name" :label="$t('coupon.validity-period')">
								<template #default="scope">
									<div class="tu-nickname">{{ scope.row.title }}</div>
									<div class="tu-subject">{{ scope.row.vaild_date }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="date" :label="$t('coupon.desc')">
								<template #default="scope">
									<div class="tu-date">{{ scope.row.desc }}</div>
								</template>
							</el-table-column>
					  	</el-table>
					  	<div v-else>
							<div class="no-data-class">{{ $t('No Data') }}</div>
						</div>
						<div style="margin-top: 20px">
							<el-button @click="noUseCouponEvent" size="small" type="danger">{{ $t('coupon.no-use-btn') }}</el-button>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<el-divider />
		<div class="pay_info">
			<div>
				<div>{{ $t('course.course-fee') }}：</div>
				<div>{{ pay_info.currency }}{{ pay_info.pay_price }}</div>
			</div>
			<div>
				<div>{{ $t('coupon.fee') }}：</div>
				<div>{{ pay_info.coupon_price != 0 ? '-' + pay_info.currency + pay_info.coupon_price : pay_info.currency + '0' }}</div>
			</div>
			<div>
				<div>{{ $t('course.pay-fee') }}：</div>
				<div>{{ pay_info.currency }}{{ pay_info.price }}</div>
			</div>
		</div>
		<el-divider />
		<div class="pay_button">
			<el-row class="btn">
			    <el-button type="primary" @click="onPayEvent">{{ $t('course.pay') }}</el-button>
			 </el-row>
		</div>
		<el-dialog
			v-model="dialogPayBooking"
			:title="$t('router.pay')"
			width="350px" :show-close="false" :destroy-on-close="true"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<div style="text-align: center;">
				<div v-if="payBookQrcode" v-html="payBookQrcode"></div>
				<div v-else style="width: 240px;height: 240px;"></div>
				<div style="line-height: 3;color: #a6a9ad;">{{ $t('course.scanning') }}</div>
				<div style="line-height: 2;color: red;font-weight: 600;">{{ $t('course.refresh') }}</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { ElMessageBox } from 'element-plus'
import CourseApi from '@/api/course'
import TimeControl from '@/utils/time-control'
import StudentApi from '@/api/student.js'

export default {
	name: "course-detail",
	components: {
	},
	data() {
		return {
			package_id: 0,
			course_id: 0,
			course_list: [],
			loading: true,
			tutor: {},
			pay_info: {
				coupon_id: 0,
				currency: '¥',
				pay_price: 0.00,
				price: 0.00,
				coupon_price: 0.00
			},
			form: {
				course_ids: [],
				course_id: 0,
				coupon_id: 0,
				stat: 0,
				pay_action: 'pay'
			},
			tab_index: '1',
			coupon: {
				list: [],
				use: [],
				no_use: [],
				params: {
					pre_page: 1000,
					type: 1,
					exist_ids: []
				},
				tabs: [
					{
						name: 'coupon.available',
						value: '1',
					},
					// {
					// 	name: 'coupon.unavailable',
					// 	value: '3'
					// }
				]
			},
			dialogPayBooking: false,
			payBookQrcode: '',
			outTradeNo: false,
			setInt: null
		}
	},
	created() {
		this.package_id = this.$route.query.pid || 0;
		this.course_id = this.$route.query.cid || 0;
		if(!this.package_id) {
			this.$router.push({path: "/course"});
		}
		this.getApiListData();
		this.handleCouponList();
	},
	watch: {
		tab_index() {
			this.handleCouponList();
		}
	},
	methods: {
		getApiListData() {
			CourseApi.package(this.package_id, this.form).then(res => {
				this.loading = false;
				if(res.data.length > 0) {
					this.tutor = res.data[0].user_info
					this.course_list = res.data

					for(var i in res.data) {
						this.pay_info.currency = res.data[i].currency
						this.pay_info.pay_price += res.data[i].price
						this.pay_info.price = this.pay_info.pay_price
					}


					this.$refs.payCourseRefTable.toggleAllSelection();
				} else {
					if(this.course_id > 0) {
						this.$router.push({path: "/course/" + this.form.course_id});
					} else {
						this.$router.push({path: "/course", query: {pid: this.package_id}});
					}
				}
			})
		},
		handleCouponList() {
			this.coupon.params.type = this.tab_index;

			if(this.tab_index == 1 && this.coupon.use.length > 0) {
				this.coupon.list = this.coupon.use;
				return ;
			}
			if(this.tab_index == 3 && this.coupon.no_use.length > 0) {
				this.coupon.list = this.coupon.no_use;
				return ;
			}

			StudentApi.coupon(this.coupon.params).then(res => {
				this.coupon.list = res.data
				if(this.tab_index == 1) {
					this.coupon.use = this.coupon.list;
				}
				if(this.tab_index == 3) {
					this.coupon.no_use = this.coupon.list;
				}
			})
		},
		handleDateShow(row) {
			let book_time = row.book_time * 1000;
			return TimeControl.getFormatTime(book_time)
		},
		handleTimeShow(row) {
			let book_time = row.book_time * 1000;
			let book_end_time = (row.book_time + row.second) * 1000;
			return TimeControl.getFormatTime(book_time, "HH:mm") + ' ~ ' + TimeControl.getFormatTime(book_end_time, "HH:mm")
		},
		onSelectCouponEvent(value) {
			if(this.tab_index != 1) {
				return;
			}
			if(!!value) {
				let item = value
				this.pay_info.coupon_id = item.id
				this.pay_info.coupon_price = item.fee
			} else {
				this.pay_info.coupon_id = 0
				this.pay_info.coupon_price = 0.00
			}
			
			this.pay_info.price = parseFloat(parseFloat(this.pay_info.pay_price) - parseFloat(this.pay_info.coupon_price)).toFixed(2);
		},
		onPayEvent() {
			this.form.coupon_id = this.pay_info.coupon_id || 0;
			if(this.form.course_ids.length <= 0) {
				this.$message.error(this.$t('course.enter-study-course'))
				return ;
			}
			if(this.setInt) {
				clearInterval(this.setInt);
			}
			CourseApi.pay(this.package_id, this.form).then(res => {
				this.dialogPayBooking = true;
				this.payBookQrcode = res.data.svg;

				// 定时任务来处理当前订单的支付情况
				this.outTradeNo = res.data.out_trade_no
				this.onPayOrderStatus()
			})
		},
		onPayOrderStatus() {
			if(!this.outTradeNo) {
				return ;
			}
			let that = this;
			if(that.setInt) {
				clearTimeout(that.setInt);
			}

			CourseApi.orderTrade({out_trade_no: this.outTradeNo}).then(res => {
				if(res.data == 1) {
					that.$message.success(that.$t('Success'));
					that.$router.push({path: "/course"});
				} else {
					that.setInt = setTimeout(that.onPayOrderStatus, 2000);
				}
			})
		},
		noUseCouponEvent() {
			this.$refs.couponTableRef.setCurrentRow()
		},
		handleSelectionCourseChange(values) {
			let pay_price = 0;
			this.form.course_ids = [];
			values.forEach((row) => {
				if(this.form.course_ids.indexOf(row.id) == -1) {
					this.form.course_ids.push(row.id);

					pay_price += row.price;
				}
			});

			this.pay_info.pay_price = pay_price;
			this.pay_info.price = parseFloat(parseFloat(pay_price) - parseFloat(this.pay_info.coupon_price)).toFixed(2);
		}
	}
}
</script>
<style lang="less">
#tu-course-pay {
	margin: 0 auto;
	width: 999px;

	.tu-tutor {
		text-align: left;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		line-height: 2;
	}

	.tu-table {
		.tu-nickname {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 600;
			padding: 6px 0;
		}

		.tu-subject, .tu-time, .tu-status {
			color: var(--el-color-info);
		}

		.tu-date {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}

		.tu-price {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}
	}

	.coupon {
		.warp {
			margin-top: 10px;
		}

		.el-table {
			.el-table__row {
				cursor: pointer;
			}
		}
	}

	.pay_info {
		text-align: right;
		margin-bottom: 15px;
		font-size: 12px;

		& > div {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			line-height: 2;

			div:last-child {
				width: 12%;
			}
		}

		& > div:last-child {

			div:last-child {
				color: #e4393c;
				font-family: Verdana;
				font-weight: 700;
				font-size: 15px;
			}
		}
	}

	.pay_button {
		.btn {
			justify-content: flex-end;
		}
	}
}
</style>